import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import { Container } from "react-grid-system";
import { Helmet } from "react-helmet";
import { MixpanelProvider } from "react-mixpanel-browser";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import PartnerDetails from "./features/partner/partner-details";
import PartnerList from "./features/partner/partner-list";
import PartnerWrapper from "./features/partner/partner-wrapper";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

Sentry.init({
  dsn: "https://9b10d7ac7b8a19bb141024ed6475621e@o1111275.ingest.us.sentry.io/4506893953204224",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/partner-content\.api\.bloomcatch-servers\.com/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if (!process.env.REACT_APP__DEVELOPMENT) {
  ReactGA.initialize("G-P52P4XGJ2P");
}

// [OPTIONAL] Set your Mixpanel token. It is up to you how this token is obtained (e.g. via env
// var). If `token` is `undefined` or otherwise falsey then `useMixpanel()` will return
// `undefined` which can be useful for environments where Mixpanel integration is not desired.
const MIXPANEL_TOKEN = "de80561f0dc1090cb068d7b4cf17fd80";

// [OPTIONAL] Custom options to pass to `mixpanel.init()`.
const MIXPANEL_CONFIG = {
  track_pageview: true,
};

const routesForPartners = [
  { path: "/demo", id: "62675b30f8aa7b5b48d92e3d" },
  { path: "/soil-vibes", id: "6266b8d6f8aa7b5b48d92de3" },
  { path: "/rewild", id: "626b4375ac83cf808019069e" },
  { path: "/greenstreet-gardens", id: "627be0c3f8aa7b5b48d92e63" },
  { path: "/plants-alive", id: "627be0f8f8aa7b5b48d92e64" },
  { path: "/merrifield-garden-center", id: "62902d0a2a39fa6e54ffcb25" },
  { path: "/yetu-jungle", id: "62dabccf2a39fa6e54ffcb36" },
  { path: "/planthouse", id: "6290fd1a2a39fa6e54ffcb2c" },
  { path: "/plant-daddy", id: "62daef9c2a39fa6e54ffcb3b" },
  { path: "/meadows-farms", id: "630652449bcdf81331fa7f21" },
  { path: "/beahive-plant-tribe", id: "63ee4944b4b72c12c08ba858" },
  { path: "/scotts-exotic-plants", id: "63fdfc06b4b72c12c08ba85d" },
  { path: "/southern-states", id: "63fe0185b4b72c12c08ba85e" },
  { path: "/reston-farm-garden-market", id: "63fe2773b4b72c12c08ba85f" },
  { path: "/botanigal", id: "3                       " },
  {
    path: "/reston-farm-garden-market-outdoor",
    id: "5                       ",
  },
  { path: "/the-sill", id: "6                       " },
  { path: "/crazy-plant-bae", id: "7                       " },
  { path: "/ace-hardware", id: "8                       " },
  {
    path: "/southern-states-manassas-outdoors",
    id: "9                       ",
  },
  { path: "/greenstreet-gardens-outdoors", id: "10                      " },
  { path: "/burke-nursery-and-garden-centre", id: "11                      " },
  { path: "/plantboxs", id: "12                      " },
  { path: "/eds-plant-world", id: "13                      " },
  {
    path: "/burke-nursery-and-garden-centre-outdoors",
    id: "14                      ",
  },
  { path: "/southern-nurseries", id: "15                      " },
  { path: "/honeysmith-bees", id: "16                      " },
  { path: "/premier-growers", id: "17                      " },
  { path: "/kens-gardens", id: "18                      " },
  { path: "/massanutten-country-corner", id: "19                      " },
  { path: "/the-happy-seed", id: "20                      " },
  { path: "/honeysmith-bees-tropicals", id: "21                      " },
  { path: "/eds-plant-world-outdoors", id: "22                      " },
  { path: "/southern-states-richmond-outdoor", id: "23                      " },
  { path: "/his-creations-garden-centers", id: "24                      " },
  { path: "/ace-hardware-annuals", id: "25                      " },
  { path: "/ace-hardware-perennials", id: "26                      " },
  { path: "/southern-states-midlothian", id: "27                      " },
  {
    path: "/massanutten-country-corner-tropicals",
    id: "28                      ",
  },
  { path: "/southern-states-charles-town-wv", id: "29                      " },
  { path: "/skillins-greenhouses", id: "30                      " },
  { path: "/winding-stair-farm", id: "31                      " },
  { path: "/ace-hardware-st-simon-island", id: "32                      " },
  { path: "/ecoasis-garden-center", id: "33                      " },
  { path: "/freedom-ag-and-energy", id: "34                      " },
];

const routeForPartner = (path: string, id: string) => {
  return (
    <Route key={id} path={path} element={<PartnerWrapper id={id} />}>
      <Route path=":partnerPlantCarePlanId" element={<PartnerDetails />} />
      <Route index element={<PartnerList />} />
    </Route>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <MixpanelProvider token={MIXPANEL_TOKEN} config={MIXPANEL_CONFIG}>
      <Helmet>
        <title>Plant Care Plans - Powered by BloomCatch</title>
      </Helmet>
      <Container>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />} />
            {routesForPartners.map((route) =>
              routeForPartner(route.path, route.id)
            )}
          </Routes>
        </BrowserRouter>
      </Container>
    </MixpanelProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
